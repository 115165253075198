import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import PostCard from "../../components/PostCard"
import SEO from "../../components/seo"
import Grid from "../../components/Grid"
import Pagination from "./Pagination"

import bgImage from "../../../static/assets/hero-banner2.png"
import * as styles from "./BlogListPage.module.scss"

export const blogListQuery = graphql`query BlogListPageQuery($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    sort: {order: DESC, fields: [frontmatter___date]}
    filter: {frontmatter: {template: {eq: "blog-post"}}}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        excerpt(pruneLength: 150)
        frontmatter {
          date(formatString: "D MMM")
          slug
          title
          pageType
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 600, height: 360, quality: 80, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`

const HeroBannerData = {
  title: "Alla artiklar",
  description:
    "I den här avdelningen finner du alla våra artiklar om avveckling och lagerbolag. Om du har frågor eller funderingar kring något speciellt så kontakta oss gärna direkt via chatt-funktionen eller telefon.",
}

class BlogListPage extends React.Component {
  render() {
    const { data } = this.props
    const { currentPage, numPages } = this.props.pageContext
    const blogSlug = "/artiklar"
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage === 2 ? blogSlug : `${blogSlug}/${currentPage - 1}`
    const nextPage = `${blogSlug}/${currentPage + 1}`

    const posts = data.allMarkdownRemark.edges
      .filter(edge => !!edge.node.frontmatter.date)
      .map(edge => <PostCard key={edge.node.id} data={edge.node} />)
    let paginationProps = {
      isFirst,
      prevPage,
      numPages,
      blogSlug,
      currentPage,
      isLast,
      nextPage,
    }

    return (
      <Layout
        className="blog-page"
        sidebar={"all-articles"}
        showTopContent
        heroData={HeroBannerData}
        pageType={"other"}
        bgImage={bgImage}
      >
        <SEO
          title={
            "Alla artiklar om avveckling och lagerbolag | Citadellet Bolagsservice AB"
          }
          description={""}
        />
        <div className={styles.BlogListPage}>
          <div className={styles.allArticles}>
            <h1 className={styles.title}>
              Alla artiklar om avveckling och lagerbolag
            </h1>

            <Grid columns={2}>{posts}</Grid>
            <Pagination {...paginationProps} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogListPage
