import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./PostCard.module.scss"
import FeaturedImageSrc from "../../../static/assets/featuredImage.png"

const PostCard = ({ data }) => {

  return (
    <article className={styles.PostCard}>
      <Link to={data.frontmatter.slug} className={styles.link}>
        <div className={styles.imageContainer}>
          {data.frontmatter.featuredImage ? (
            <GatsbyImage
              image={data.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={data.frontmatter.title + " - Featured image"}
              className={styles.featuredImageStyle} />
          ) : (
            <img
              src={FeaturedImageSrc}
              className={styles.featuredImageStyle}
              alt={data.frontmatter.title}
            />
          )}
        </div>

        <div className={styles.postContent}>
          {data.frontmatter.pageType === "sell" ? (
            <div className={styles.sell}>Avveckla bolag</div>
          ) : (
            <div className={styles.buy}>Köpa lagerbolag</div>
          )}
          <h2 className={styles.title}>
            {data.frontmatter.title}
          </h2>
          <p className={styles.excerpt}>{data.excerpt}</p>
          <p className={styles.readMore}>LÄS MER</p>
        </div>
      </Link>
    </article>
  );
}

export default PostCard
