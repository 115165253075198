import React from "react"
import classNames from 'classnames'

import * as styles from './Grid.module.scss'

const Grid = ({ columns = 3, children }) => (
  <div className={classNames(styles.Grid, columns === 2 && styles.twoCols)}>
    {children}
  </div>
)

export default Grid
