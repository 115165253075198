import React from "react"
import { Link } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import classNames from "classnames"

import * as styles from "./Pagination.module.scss"

const Pagination = props => (
  <div className={styles.Pagination}>
    <ul className={styles.itemsWrapper}>
      {!props.isFirst && (
        <li>
          <Link to={props.prevPage} rel="prev">
            <span className="icon -left">
              <RiArrowLeftLine />
            </span>{" "}
            Föregående
          </Link>
        </li>
      )}
      {Array.from({ length: props.numPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`}>
          <Link
            to={i === 0 ? props.blogSlug : `${props.blogSlug}/${i + 1}`}
            className={classNames(
              styles.num,
              props.currentPage === i + 1 && styles.active
            )}
          >
            {i + 1}
          </Link>
        </li>
      ))}
      {!props.isLast && (
        <li>
          <Link to={props.nextPage} rel="next">
            Nästa{" "}
            <span className="icon -right">
              <RiArrowRightLine />
            </span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

export default Pagination
